<template>
  <div>
    <LinksCSVExportModalV2 ref="links_csv_export_modal_v2"></LinksCSVExportModalV2>
    <BulkImportModalV2 ref="links_bulk_import_modal"></BulkImportModalV2>
    <div v-if="!isDashboard" class="flex px-3 py-3.5 justify-between items-center">
      <div class="w-full flex gap-x-2">
        <ButtonWithDropdown
          id="create-new-link"
          type="button"
          buttonClass="btn-sm"
          @click="createNewLink()"
          @update="$refs.links_bulk_import_modal.importCsv = $event"
          v-if="getProfile.policy.can_add_links"
        >
          <template v-slot:label>Create New</template>
        </ButtonWithDropdown>
        <SearchField
          id="search-workspace"
          v-model="getLinks.search"
          @input="fetchLinksAndAnalytics()"
          type="text"
          label="Search links"
          iconName="lock.svg"
          customClass="h-8 bg-white with_border"
        >
          <template v-slot:right_btn>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 17 18" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.95166 8.22033C2.95166 5.32428 5.29938 2.97656 8.19543 2.97656C11.0915 2.97656 13.4392 5.32428 13.4392 8.22033C13.4392 9.6277 12.8848 10.9056 11.9824 11.8475C11.9532 11.8708 11.9249 11.896 11.8979 11.923C11.8708 11.9501 11.8457 11.9783 11.8224 12.0075C10.8805 12.9097 9.6027 13.4641 8.19543 13.4641C5.29938 13.4641 2.95166 11.1164 2.95166 8.22033ZM12.5616 14.0009C11.3475 14.9193 9.83508 15.4641 8.19543 15.4641C4.19481 15.4641 0.95166 12.221 0.95166 8.22033C0.95166 4.21971 4.19481 0.976562 8.19543 0.976562C12.1961 0.976562 15.4392 4.21971 15.4392 8.22033C15.4392 9.86011 14.8943 11.3726 13.9758 12.5867L16.7071 15.3181C17.0977 15.7086 17.0977 16.3418 16.7071 16.7323C16.3166 17.1228 15.6835 17.1228 15.2929 16.7323L12.5616 14.0009Z" fill="#757A8A"/>
            </svg>
          </template>
        </SearchField>
        <div v-if="getLinks.archive" class="border rounded-[6.25rem] bg-yellow-100 bg-opacity-60  gap-x-2 px-[0.75rem] py-[0.5rem] flex h-8 justify-between items-center">
          <p class="text-[#3C4549] text-[0.875] font-poppins leading-none">Archived Links</p>
          <div @click="showLinksArchive(false)" class="w-3 h-3 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.79881 2.26275C9.0917 1.96986 9.0917 1.49499 8.79881 1.20209C8.50592 0.909199 8.03104 0.909199 7.73815 1.20209L5.00045 3.93979L2.26275 1.20209C1.96986 0.909199 1.49498 0.909199 1.20209 1.20209C0.909199 1.49499 0.909199 1.96986 1.20209 2.26275L3.93979 5.00045L1.20209 7.73815C0.909199 8.03104 0.909199 8.50592 1.20209 8.79881C1.49498 9.0917 1.96986 9.0917 2.26275 8.79881L5.00045 6.06111L7.73815 8.79881C8.03104 9.0917 8.50592 9.0917 8.79881 8.79881C9.0917 8.50592 9.0917 8.03104 8.79881 7.73815L6.06111 5.00045L8.79881 2.26275Z" fill="#757A8A"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="w-full flex justify-center items-center">
        <p class="text-[#3C4549] mr-1 text-lg font-semibold font-poppins leading-none">Links</p>
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.70445 2.29492C4.10627 2.29492 2 4.40121 2 6.99947C2 9.59773 4.10627 11.704 6.70445 11.704C9.30263 11.704 11.4089 9.59773 11.4089 6.99947C11.4089 4.40121 9.30263 2.29492 6.70445 2.29492ZM0.5 6.99947C0.5 3.5728 3.27781 0.794922 6.70445 0.794922C10.1311 0.794922 12.9089 3.5728 12.9089 6.99947C12.9089 10.4261 10.1311 13.204 6.70445 13.204C3.27781 13.204 0.5 10.4261 0.5 6.99947ZM6.70476 6.04891C7.11898 6.04895 7.45473 6.38477 7.45469 6.79898L7.45445 9.49304C7.45441 9.90726 7.1186 10.243 6.70438 10.243C6.29017 10.2429 5.95441 9.90712 5.95445 9.49291L5.95469 6.79885C5.95473 6.38463 6.29055 6.04888 6.70476 6.04891ZM6.70445 3.75581C6.29024 3.75581 5.95445 4.0916 5.95445 4.50581C5.95445 4.92002 6.29024 5.25581 6.70445 5.25581H6.71029C7.12451 5.25581 7.46029 4.92002 7.46029 4.50581C7.46029 4.0916 7.12451 3.75581 6.71029 3.75581H6.70445Z" fill="#3C4549"/></svg>
      </div>
      <div class="w-full flex items-center justify-end space-x-2">
        <DatePicker
          className="links_date_filter"
          customClass="with_border"
          @input="dateFilter"
        ></DatePicker>
        <div class="manage-dropdown shortcut_menu_dropdown">
          <b-dropdown offset="25, 0"
                      right
                      class="dropdown-menu-left hide_caret default_style_dropdown"
                      ref="tags_dropdown_select"
                      :no-caret="true">
            <div class="dropdown_header border !border-[#757A8A] font-semibold h-8 px-2.5 py-2 rounded-lg d-flex align-items-center " slot="button-content">
              <span class="text">{{ getSelectedFilter('links', 'tags') }}</span>
              <span class="arrow_icon ml-auto"><i class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"></i></span>
            </div>
            <ul style="max-height: 20rem; overflow-x: hidden; overflow-y: auto;" class="w-full text-[#202324] font-poppins py-1 text-[0.875rem] shadow-lg bg-white rounded-tl-lg font-poppins rounded-bl-lg rounded-br-lg">
              <li class="cursor-pointer border-b group hover:rounded-tl-lg px-[1rem] py-[0.75rem] flex justify-between font-poppins items-center" v-if="getTagList && getTagList.length > 0">
                <Checkbox
                  id="tag_check_all"
                  label="All tags"
                  v-model="select_all_tags"
                  @click="$refs.tags_dropdown_select.hide(true)"
                  @change="tagsSelectAll(select_all_tags); fetchLinksAndAnalytics()"
                  labelClass="font-semibold text-[#3C4549]"
                  customClass="w-full justify-between flex-row-reverse"
                ></Checkbox>
              </li>

              <template v-if="getTagList && getTagList.length">
                <template v-for="tag in getTagList">

                  <li class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex justify-between font-poppins items-center">
                    <Checkbox
                      :id="tag._id"
                      :label="limitTextLength(tag.tag, 21)"
                      v-model="getLinks.tags"
                      @click="$refs.tags_dropdown_select.hide(true)"
                      @change="fetchLinksAndAnalytics()"
                      :inputValue="tag._id"
                      labelClass="text-[#3C4549]"
                      customClass="w-full justify-between flex-row-reverse"
                    ></Checkbox>
                  </li>

                </template>
              </template>
              <li class="text text-center py-[0.75rem]" v-else-if="!getTagList.length">
                You do not have any Tags.
              </li>
            </ul>
          </b-dropdown>
        </div>
        <div class="manage-dropdown shortcut_menu_dropdown">
          <b-dropdown offset="25, 0"
                      right
                      class="dropdown-menu-left hide_caret default_style_dropdown"
                      ref="campaign_dropdown_select"
                      :no-caret="true">
            <div class="dropdown_header border !border-[#757A8A] font-semibold h-8 px-2.5 py-2 rounded-lg d-flex align-items-center " slot="button-content">
              <span @click="fetchCampaignOnDropdown" class="text">{{ getSelectedFilter('links', 'campaign') }}</span>
              <span class="arrow_icon ml-auto"><i class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"></i></span>
            </div>
            <ul style="max-height: 20rem; overflow-x: hidden; overflow-y: auto;" id="campaign-dropdown-scroll" @scroll="lazyScroll('campaign-dropdown-scroll','fetchCampaignsList')" class="w-full text-[#202324] font-poppins py-1 text-[0.875rem] shadow-lg bg-white rounded-tl-lg font-poppins rounded-bl-lg rounded-br-lg">
              <li class="cursor-pointer border-b group hover:rounded-tl-lg px-[1rem] py-[0.75rem] flex justify-between font-poppins items-center" v-if="getComponentCampaignList && getComponentCampaignList.length > 0">
                <Checkbox
                  id="campaign_check_all"
                  label="All Campaigns"
                  v-model="select_all_campaigns"
                  @click="$refs.campaign_dropdown_select.hide(true); campaginsSelectAll(select_all_campaigns)"
                  labelClass="font-semibold text-[#3C4549]"
                  customClass="w-full justify-between flex-row-reverse"
                ></Checkbox>
              </li>

              <template v-if="getComponentCampaignList && getComponentCampaignList.length">
                <template v-for="cta in getComponentCampaignList">

                  <li class="cursor-pointer group  hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex justify-between font-poppins items-center">
                    <Checkbox
                      :id="cta._id"
                      :label="limitTextLength(cta.name, 21)"
                      v-model="getLinks.campaign"
                      @click="fetchLinksAndAnalytics()"
                      :inputValue="cta._id"
                      labelClass="text-[#3C4549]"
                      customClass="w-full justify-between flex-row-reverse"
                    ></Checkbox>
                  </li>

                </template>
              </template>
              <li v-else-if="getCampaignLoaders.retrieve" class="list_item_li mt-1">
                <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
              </li>
              <li class="text text-center px-[0.3rem] py-[0.75rem]" v-else-if="!getComponentCampaignList.length">
                You do not have any Campaign.
              </li>
            </ul>
          </b-dropdown>
        </div>
        <div @click="$refs.links_csv_export_modal_v2.preRequiredModelData(); $bvModal.show('links-csv-export-modal-v2')" v-tooltip="'CSV Export'" class="cursor-pointer border !border-[#757A8A] flex flex-col items-center justify-center rounded-lg p-2 h-8">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-[1rem] h-[1rem]" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5301 5.33111C12.2372 5.624 11.7623 5.624 11.4694 5.33111L8.74976 2.61144V10.4009C8.74976 10.8151 8.41397 11.1509 7.99976 11.1509C7.58554 11.1509 7.24976 10.8151 7.24976 10.4009V2.61144L4.53009 5.33111C4.23719 5.624 3.76232 5.624 3.46943 5.33111C3.17653 5.03822 3.17653 4.56334 3.46943 4.27045L7.46943 0.270451C7.76232 -0.0224421 8.23719 -0.0224421 8.53009 0.270451L12.5301 4.27045C12.823 4.56334 12.823 5.03822 12.5301 5.33111ZM0.799805 9.65078C1.21402 9.65078 1.5498 9.98657 1.5498 10.4008V13.6008C1.5498 13.8262 1.63936 14.0424 1.79876 14.2018C1.95817 14.3612 2.17437 14.4508 2.3998 14.4508H13.5998C13.8252 14.4508 14.0414 14.3612 14.2008 14.2018C14.3603 14.0424 14.4498 13.8262 14.4498 13.6008V10.4008C14.4498 9.98657 14.7856 9.65078 15.1998 9.65078C15.614 9.65078 15.9498 9.98657 15.9498 10.4008V13.6008C15.9498 14.224 15.7022 14.8218 15.2615 15.2625C14.8208 15.7032 14.2231 15.9508 13.5998 15.9508H2.3998C1.77655 15.9508 1.17881 15.7032 0.738104 15.2625C0.297393 14.8218 0.0498047 14.224 0.0498047 13.6008V10.4008C0.0498047 9.98657 0.385591 9.65078 0.799805 9.65078Z" fill="#757A8A"/>
          </svg>
        </div>
        <div class="manage-dropdown shortcut_menu_dropdown">
          <b-dropdown ref="other_filters_dropdown" id="dropdown-1" right offset="25, 0" class="dropdown-menu-right hide_dropdown_caret default_style_dropdown" :no-caret="false">
            <div class="cursor-pointer border !border-[#757A8A] flex flex-col items-center justify-center rounded-lg p-2 w-8 h-8" slot="button-content">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-1 h-16" viewBox="0 0 4 16" fill="none">
                <circle cx="1.99967" cy="7.9987" r="1.66667" fill="#757A8A"/>
                <circle cx="1.99967" cy="2.33268" r="1.66667" fill="#757A8A"/>
                <circle cx="1.99967" cy="13.6647" r="1.66667" fill="#757A8A"/>
              </svg>
            </div>

            <ul class="w-full text-[#3C4549] py-1 text-[0.875rem] shadow-lg bg-white font-poppins rounded-b-lg rounded-tl-lg">
              <li @click="showLinksArchive(!getLinks.archive), $refs.other_filters_dropdown.hide(true)" class="cursor-pointer leading-none hover:bg-[#F4F6FA] w-full py-[0.75rem] flex text-[0.875rem] px-[1rem] justify-between font-poppins items-center">
                <p>Archived Links</p>
                <svg v-if="getLinks.archive" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                  <path d="M10.5 0.625L4.3125 7.375L1.5 4.30682" stroke="#2560D7" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </li>

              <b-dropdown  id="dropdown-2" right offset="-9, 0" dropleft text="Drop-Left" class="my_inner_dropdown hide_dropdown_caret default_style_dropdown" :no-caret="true">
                <div class="cursor-pointer leading-none group text-[0.875rem] hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] h-11 flex justify-between font-poppins items-center" slot="button-content">
                  <p>Show/hide Columns</p>
                  <div class="w-5 h-5 flex flex-col items-end justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-1.5 h-2.5" viewBox="0 0 6 10" fill="none">
                      <path d="M1 9L5 5L1 1" stroke="#757A8A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </div>

                <transition>
                  <ul class=" max-h-[300px] !overflow-y-scroll ">

                    <li class="border-b py-[0.75rem] px-[1rem]">
                      <Checkbox
                        id="link_column_select_all"
                        v-model="checkAllColumn"
                        @change="linkColumnSelectAll()"
                        :inputValue="getLinksBulkSelection.all"
                        label="All Columns"
                        labelClass="text-[#3C4549] font-semibold"
                        customClass="w-full justify-between flex-row-reverse"
                      ></Checkbox>
                    </li>

                    <li v-for="(columns,index) in getLinks.tableColumns" class="py-[0.75rem] px-[1rem]">

                      <Checkbox
                        :id="'column'+ columns.id"
                        v-model="columns.status"
                        @change="linksSelectedColumnsCount()"
                        :inputValue="getLinksBulkSelection.all"
                        :label="columns.name"
                        labelClass="text-[#3C4549]"
                        customClass="w-full justify-between flex-row-reverse"
                      ></Checkbox>
                    </li>

                  </ul>
                </transition>
              </b-dropdown>
            </ul>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div v-else class="flex px-3 py-3.5 justify-between items-center">
      <div class="w-full flex justify-between items-center">
        <div class="flex justify-center items-center">
          <p class="text-[#3C4549] mr-1 text-lg font-semibold font-poppins leading-none">Links</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.70445 2.29492C4.10627 2.29492 2 4.40121 2 6.99947C2 9.59773 4.10627 11.704 6.70445 11.704C9.30263 11.704 11.4089 9.59773 11.4089 6.99947C11.4089 4.40121 9.30263 2.29492 6.70445 2.29492ZM0.5 6.99947C0.5 3.5728 3.27781 0.794922 6.70445 0.794922C10.1311 0.794922 12.9089 3.5728 12.9089 6.99947C12.9089 10.4261 10.1311 13.204 6.70445 13.204C3.27781 13.204 0.5 10.4261 0.5 6.99947ZM6.70476 6.04891C7.11898 6.04895 7.45473 6.38477 7.45469 6.79898L7.45445 9.49304C7.45441 9.90726 7.1186 10.243 6.70438 10.243C6.29017 10.2429 5.95441 9.90712 5.95445 9.49291L5.95469 6.79885C5.95473 6.38463 6.29055 6.04888 6.70476 6.04891ZM6.70445 3.75581C6.29024 3.75581 5.95445 4.0916 5.95445 4.50581C5.95445 4.92002 6.29024 5.25581 6.70445 5.25581H6.71029C7.12451 5.25581 7.46029 4.92002 7.46029 4.50581C7.46029 4.0916 7.12451 3.75581 6.71029 3.75581H6.70445Z" fill="#3C4549"/></svg>
        </div>
      </div>
      <div class="w-full flex items-center justify-end space-x-2">
        <SearchField
          id="search-workspace"
          v-model="getLinks.search"
          @input="fetchLinksAndAnalytics()"
          type="text"
          label="Search links"
          iconName="lock.svg"
          customClass="h-8 bg-white without_border"
        >
          <template v-slot:right_btn>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 17 18" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.95166 8.22033C2.95166 5.32428 5.29938 2.97656 8.19543 2.97656C11.0915 2.97656 13.4392 5.32428 13.4392 8.22033C13.4392 9.6277 12.8848 10.9056 11.9824 11.8475C11.9532 11.8708 11.9249 11.896 11.8979 11.923C11.8708 11.9501 11.8457 11.9783 11.8224 12.0075C10.8805 12.9097 9.6027 13.4641 8.19543 13.4641C5.29938 13.4641 2.95166 11.1164 2.95166 8.22033ZM12.5616 14.0009C11.3475 14.9193 9.83508 15.4641 8.19543 15.4641C4.19481 15.4641 0.95166 12.221 0.95166 8.22033C0.95166 4.21971 4.19481 0.976562 8.19543 0.976562C12.1961 0.976562 15.4392 4.21971 15.4392 8.22033C15.4392 9.86011 14.8943 11.3726 13.9758 12.5867L16.7071 15.3181C17.0977 15.7086 17.0977 16.3418 16.7071 16.7323C16.3166 17.1228 15.6835 17.1228 15.2929 16.7323L12.5616 14.0009Z" fill="#757A8A"/>
            </svg>
          </template>
        </SearchField>
        <DatePicker
          className="links_date_filter"
          customClass="without_border"
          @input="dateFilter"
        ></DatePicker>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { linkTypes } from '@/state/modules/mutation-types'

export default {
  data () {
    return {
      select_all_campaigns: false,
      select_all_tags: false,
      checkAllColumn:false,
      isDropdown2Visible: false
    }
  },
  computed: {
    ...mapGetters([
      'getProfile',
      'getLinks',
      'getLinksBulkSelection',
      'getComponentCampaignList',
      'getCampaignLoaders',
      'getTagList'
    ]),
    isDashboard() {
      return this.$route.name === 'dashboard'
    }
  },
  created () {
    this.$store.commit(linkTypes.SET_LINKS_DATE_RANGE, {
      label: ' All Time',
      value: ''
    })
    this.$set(this.getLinks, 'tags', [])
    this.$set(this.getLinks, 'campaign', [])
  },
  mounted () {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = true
      }
    })

    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })
  },
  components: {
    LinksCSVExportModalV2: () => import('@/views/pages/links/dialogs/LinksCSVExportModalV2.vue'),
    BulkImportModalV2: () => import('@/views/pages/links/dialogs/LinksBulkImportModalV2.vue'),
    ButtonWithDropdown: () => import('@/ui/ui-kit/v2/ButtonWithDropdown.vue'),
    SearchField: () => import('@/ui/ui-kit/v2/SearchField.vue'),
    SwitchButton: () => import('@/ui/ui-kit/v2/SwitchButton.vue'),
    DatePicker: () => import('@/ui/ui-kit/v2/DatePicker.vue'),
    DropdownSelect: () => import('@/ui/ui-kit/v2/DropdownSelect.vue'),
    Checkbox: () => import('@/ui/ui-kit/v2/Checkbox.vue'),
  },
  methods: {
    ...mapActions(['fetchLinks', 'fetchLinkAnalytics']),
    linkColumnSelectAll () {
      if (this.checkAllColumn) {
        this.getLinks.tableColumns.forEach(item => item.status = true)
      } else {
        this.getLinks.tableColumns.forEach(item => item.status = false)
      }
    },
    async fetchLinksAndAnalytics (page = 1) {
      await this.fetchLinks(page)
      this.fetchLinkAnalytics()
    },
    async showLinksArchive (status) {
      this.$store.commit(linkTypes.SET_LINKS_ARCHIVE_VALUE, status)
      await this.fetchLinks()
      this.fetchLinkAnalytics()
    },
    linksSelectedColumnsCount () {
      this.checkAllColumn = (this.getLinks.tableColumns.filter(item => item.status === true).length === this.getLinks.tableColumns.length)
    },
    campaginsSelectAll () {
      let campaigns = (this.select_all_campaigns) ? [] : this.getComponentCampaignList.map(item => item._id)
      this.$store.commit(linkTypes.SET_LINKS_CAMPAIGNS_LIST, campaigns)
      this.fetchLinksAndAnalytics(1)
    },
    /**
     * Link date picker filter
     * @param event
     */
    dateFilter(event) {
      // calling the content mixing method to reflect the range filter.
      this.$store.commit(linkTypes.SET_LINKS_DATE_RANGE, event)
      this.paginateLinks(1)
    },
    tagsSelectAll (event) {
      if (event) {
        let tags = this.getTagList.map(item => item._id)
        this.$store.commit(linkTypes.SET_LINK_SELECTED_TAGS, tags)
      } else {
        this.$store.commit(linkTypes.SET_LINK_SELECTED_TAGS, [])
      }
      this.fetchLinksAndAnalytics(1)
    },
  },
  watch: {
    'getLinks.search' (value) {
      if (value.length === 0) {
        this.fetchLinksAndAnalytics()
      } else if (value && value.length >= 3) {
        this.fetchLinksAndAnalytics()
      }
    },
    'getLinks.tags.length' (value) {
      this.select_all_tags = (this.getTagList.length === value)
    },
    'getLinks.campaign.length' (value) {
      this.select_all_campaigns = (this.getComponentCampaignList.length === value)
    },
  }
}
</script>
